/**
 * 获取统计事件参数
 * https://docs.google.com/spreadsheets/d/1SWkDqeTKy5AW8PNkQgr8YR5VFPe9KP7fUQ72LV6lHwY/edit?pli=1#gid=0
 */

import { isArray, isString, map, omit, pick } from 'lodash-es'
import { isValid } from '@lib/utils'
import { modeType } from '@lib/parse'
import { houseSearchType, typeOfId } from '@lib/config'

const baseEvtParams = {
  [houseSearchType.property]: { vertical: 'buy', vertical_type: 'buy', sale_type: 'buy' },
  [houseSearchType.estimate]: { vertical: 'estimate', vertical_type: 'estimate', sale_type: 'estimate' },
  [houseSearchType.rent]: { vertical: 'rent', vertical_type: 'rent', sale_type: 'rent' },
  [houseSearchType.commercialForSale]: { vertical: 'commercial', vertical_type: 'commercial for sale', sale_type: 'commercial for sale' },
  [houseSearchType.commercialForLease]: { vertical: 'commercial', vertical_type: 'commercial for lease', sale_type: 'commercial for lease' },
  [houseSearchType.rural]: { vertical: 'rural', vertical_type: 'rural', sale_type: 'sale' },
  [houseSearchType.sold]: { vertical: 'estimate', vertical_type: 'sold', sale_type: 'sold' },
}

const viewTypes = {
  [modeType.map]: 'map',
  [modeType.listing]: 'multiple',
  [modeType.single]: 'single',
}

function getEventBaseData(house) {
  const { type, typeId, newHomes } = house || {}
  const target = baseEvtParams[type]
    || baseEvtParams[typeOfId[typeId]]
    || baseEvtParams[houseSearchType.property]
  
  const tmp = {
    page_type: 'listing', 
    ...target 
  }

  if(type === houseSearchType.property && newHomes) {
    tmp.vertical_type = 'new home'
  }
  
  return tmp
}

function tos(r) {
  return isValid(r) ? String(r) : ''
}

/**
 * 获取房源详情基本统计参数
 */
function getHouseDetailData(house) {
  const tmp = pick(house || {}, ['address', 'bedrooms', 'bathrooms'])
  return {
    ...tmp,
    region_name: tos(house?.regionName || house?.region_name),
    district_name: tos(house?.districtName || house?.district_name),
    suburb_name: tos(house?.suburbName || house?.suburb_name),
    property_types: tos(house?.category?.slug),
    search_price: tos(house?.priceSearch),
    price_method: tos(house?.priceMethod?.eng),
    office_name: tos(house?.office?.name),
    agency_brand: tos(house?.office?.brand),
    property_id: tos(house?.propertyId),
    listing_id: tos(house?.id),
    slug_id: String(house?.slug).split('/').reverse()[0],
    agent_id: isArray(house?.agents) ? map(house.agents, 'id').join(',') : '',
    __extra__: {
      ...pick(house || {}, ['regionId', 'districtId', 'suburbId', 'typeId']),
      categoryId: house?.category?.id,
      priceMethodId: house?.priceMethod?.id,
    }
  }
}

/**
 * 获取房源列表数据
 */
function getHouseListData(house) {
  const tmp = {
    region_name: tos(house?.regionName),
    district_name: tos(house?.districtName),
    suburb_name: tos(house?.suburbName),
    property_types: '',
    search_price: '',
    office_name: house?.officeName,
    agency_brand: '',
    property_id: '',
    listing_id: house?.id,
    slug_id: String(house?.slug).split('/').reverse()[0],
    agent_id: isArray(house?.simpleAgents) ? map(house.simpleAgents, 'id').join(',') : ''
  }

  tmp.address = tos(house?.address)
  tmp.bedrooms = tos(house?.propertyIcon?.bedrooms)
  tmp.bathrooms = tos(house?.propertyIcon?.bathrooms)

  return tmp
}

/**
 * view_house
 */
export function getViewHouseParam(house) {
  return {
    ...getHouseDetailData(house),
    ...getEventBaseData(house)
  }
}

/**
 * view_house 列表
 */
export function getViewHouseListingsParam(house) {
  return {
    ...getHouseListData(house),
    ...getEventBaseData(house)
  }
}

/**
 * view_house api param
 */
export function viewHouseParam(house, houseId = 0, agencyOfficeId = 0, agentProp = 'agents') {
  return {
    houseId,
    suburbId: house.suburbId || 0,
    propertyId: house.propertyId || 0,
    animation: house.animation,
    agentId: isArray(house?.[agentProp]) ? map(house[agentProp], 'id').join(',') : '',
    agencyOfficeId,
  }
}

/**
 * house_search
 */
export function getHouseSearchParam(searchParam, isMapListing, evtcfg) {
  const { 
    school, street, listing, type, bathroom, bedroom, 
    price, order, category, searchType = 'direct_search',
  } = searchParam || {}
  
  const [priceStart, priceEnd] = isArray(price) ? price : []
  
  // category name
  let categoryName = ''
  if((isString(category) || isArray(category)) && evtcfg?.subType) {
    categoryName = map(
      isArray(category) ? category : category.split(','),
      (key) => evtcfg?.subType?.[key]
    ).join(',')
  }
  
  const viewType = isMapListing
    ? viewTypes[modeType.map]
    : searchParam?.list
      ? viewTypes[modeType.listing]
      : viewTypes[modeType.single]
  
  // 统一 region,district,suburb 名称
  const getRealName = (name) => {
    const target = searchParam[name]
    const evtObj = evtcfg?.[name]

    return isArray(target) 
      ? map(target, (it) => (evtObj?.[it.value] || it.slug || it.label)).join(',') 
      : ''
  }

  return {
    vertical: 'search',
    // vertical_type: 'buy',
    page_type: 'search',
    search_type: searchType,
    search_category: type,
    region_name: getRealName('region'),
    district_name: getRealName('district'),
    suburb_name: getRealName('suburb'),
    school_name: tos(school?.label),
    street_name: tos(street?.label),
    address: tos(listing?.label),
    bedrooms: isArray(bedroom) ? bedroom.join(',') : '',
    bathrooms: isArray(bathroom) ? bathroom.join(',') : '',
    property_types: categoryName,
    price_from: tos(priceStart),
    price_to: (priceEnd === 'any' || !priceEnd) ? '' : tos(priceEnd),
    view_type: viewType,
    sort_by: tos(order),
  }
}

/**
 * add_favourite
 * add_favourite_success
 */
export function getFavoriteAndShareData(house) {
  // eslint-disable-next-line no-underscore-dangle
  const opt = house?.__islist__
    ? getHouseListData(house)
    : omit(getHouseDetailData(house), ['price_method'])
  
  return { ...opt, ...getEventBaseData(house) }
}

/**
 * push listing通过获取数据
 */
export function getListingData(house) {
  const opt = getHouseDetailData(house)
  return { ...opt, ...getEventBaseData(house) }
}
