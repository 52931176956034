/**
 * useDialog
 * 
 * @Author: Focci
 * @Date: 2023-08-14 22:37:35
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-14 22:37:35
 */

'use client'

import CustomDialog from '@comp/dialog/CustomDialog'
import { memo, useCallback, useState } from 'react'

export default function useDialog(defaultValue = false) {
  const [open, setOpen] = useState(defaultValue)
  const Dialog = memo(({
    title,
    contentClassName = 'bg-white p-4',
    children,
    ...dialogProps
  }) => {
    if(!open) {
      return null
    }
    
    return (
      <CustomDialog 
        fullScreen
        width="45rem"
        open={open}
        onClose={() => setOpen(false)}
        {...dialogProps}
      >
        <div className={contentClassName}>
          {title && (
            <div className="text-center text-f1.2 font-medium my-6">
              {title}
            </div>
          )}
          {open && children}
        </div>
      </CustomDialog>
    )
  })

  const openDialog = useCallback(() => setOpen(true), [])
  const closeDialog = useCallback(() => setOpen(false), [])

  return {
    openDialog,
    closeDialog,
    Dialog
  }
}
